import * as AuthenticationActions from "./constants";
import { AuthState, SignIn, SignOut, SetUserDetails } from "./types";

const initialState: AuthState = {
  user: null,
  token: null,
};

export default function authentication(
  state = initialState,
  action: SignIn | SignOut | SetUserDetails,
): AuthState {
  switch (action.type) {
    case AuthenticationActions.SIGN_IN:
      return {
        ...state,
        ...action.payload,
      };
    case AuthenticationActions.SIGN_OUT:
      return {
        user: null,
        // lastLogin: null,
        token: null,
      };
    case AuthenticationActions.SET_USER_DETAILS:
      return { ...state, user: { ...state.user, ...action.payload } };
    default:
      return state;
  }
}
