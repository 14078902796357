import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const persistedStore = localStorage.getItem("persist:root");

let token: string | null = null;

if (persistedStore) {
  const store = JSON.parse(persistedStore);
  const { authentication } = store;
  const authJSON = JSON.parse(authentication);
  token = authJSON.token;
}

let client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URL_API,
  cache: new InMemoryCache(),
});

if (typeof token === "string" && token.length) {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL_API,
    credentials: "same-origin",
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
}

export default client;
