import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { signOut } from "flux/authentication/actions";

const useAuth = () => {
  const history = useHistory();

  const { auth } = useSelector((state: { authentication: AuthState }) => ({
    auth: state.authentication,
  }));
  const dispatch = useDispatch();

  const logout = async () => {
    dispatch(signOut());
    history.push("/");
  };

  /**
   * Check if user had complete the profile setup
   * @param {object} user - User object
   * @returns {Boolean}
   */
  const isProfileComplete = () => {
    const { user } = auth;
    return (
      !user?.email ||
      !user?.firstName ||
      !user?.dateOfBirth ||
      !user?.lastName ||
      !user?.gender ||
      !user?.phoneNumber
    );
  };

  return {
    auth,
    logout,
    isProfileComplete,
  };
};

export default useAuth;
