import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    position: "relative",
    backgroundColor: "transparent",
    height: "calc(100vh - 150px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface IProps {
  size?: "large" | "medium" | "small";
}

const Loading = ({ size = "medium" }: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.loadingWrapper}>
      <CircularProgress
        size={size === "large" ? 60 : size === "medium" ? 40 : 30}
      />
    </div>
  );
};

export default Loading;
