import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import useAuth from "components/hooks/useAuth";

const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const { auth } = useAuth();

  return auth?.user ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: "/sign-in" }} />
  );
};

export default ProtectedRoute;
