import React, { lazy, Suspense } from "react";
import styled from "styled-components";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Loading from "components/ui/states/Loading";
import ProtectedRoute from "./utils/ProtectedRoute";

// const Consults = lazy(() => import('pages/consults'));
const Patients = lazy(() => import("pages/patients"));
// const Clinicians = lazy(() => import('pages/clinicians'));
const Landing = lazy(() => import("pages/dashboad"));
const Partners = lazy(() => import("pages/partners"));
const Partner = lazy(() => import("pages/partner"));
const Beneficiary = lazy(() => import("pages/beneficiary"));
const Profile = lazy(() => import("pages/profile"));
const SignIn = lazy(() => import("pages/sign-in"));
const NotFound = lazy(() => import("pages/not-found"));

const LoadingWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const App: React.FC = () => {
  return (
    <div>
      <BrowserRouter>
        <Suspense
          fallback={
            <LoadingWrapper>
              <Loading size="large" />
            </LoadingWrapper>
          }
        >
          <Switch>
            <ProtectedRoute path="/" exact component={Landing} />
            <ProtectedRoute path="/partners" exact component={Partners} />
            <ProtectedRoute path="/partners/:id" exact component={Partner} />
            <ProtectedRoute
              path="/partners/:partnerId/beneficiaries/:id"
              exact
              component={Beneficiary}
            />
            <ProtectedRoute path="/profile" exact component={Profile} />
            <ProtectedRoute path="/patients" component={Patients} exact />
            <Route path="/sign-in" component={SignIn} />
            <Route component={NotFound} />

            {/* <ProtectedRoute path="/consults" exact component={Consults} />
            <ProtectedRoute path="/clinicians" component={Clinicians} exact /> */}
          </Switch>
        </Suspense>
      </BrowserRouter>
    </div>
  );
};

export default App as any;

// export default App;
