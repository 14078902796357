import { SignIn, SignOut, AuthState, SetUserDetails, UserState } from "./types";
import * as AuthenticationActions from "./constants";

export function signIn(state: AuthState): SignIn {
  return {
    type: AuthenticationActions.SIGN_IN,
    payload: state,
  };
}

export function signOut(): SignOut {
  return {
    type: AuthenticationActions.SIGN_OUT,
  };
}

export function setUserDetails(state: UserState): SetUserDetails {
  return {
    type: AuthenticationActions.SET_USER_DETAILS,
    payload: state,
  };
}
